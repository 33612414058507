////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgb(255, 255, 255);
}
.bg-secondaryColor {
  background-color: rgb(223, 204, 166);
}
.bg-thirdColor {
  background-color: rgb(17, 17, 17);
}
.bg-fourthColor {
  background-color: rgb(255, 255, 255);
}
.bg-fifthColor {
  background-color: rgb(220, 220, 220);
}
.bg-sixthColor {
  background-color: rgba(124, 79, 27, 0.64);
}
.bg-seventhColor {
  background-color: rgb(255, 255, 255);
}
